.image img{
    text-decoration: none;
    outline: none;
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 10px;
    background-color: white;
    transition: all ease 200ms;
    &:hover {
        transform: scale(108%);
    }
}