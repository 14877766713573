@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,700;1,400&display=swap');

// RESET
* {
    box-sizing:border-box;
    &::selection {
        background-color: var(--primaryDark);
    }
}

body {
    font-family: 'Montserrat', sans-serif;
    margin: 0;
    padding: 0;
    html {
        scroll-behavior: smooth;
    }
    ul, ol, li{
        list-style: none;
        margin: 0;
    }
    &::-webkit-scrollbar {
        display: none;
    }
}

// Titles
.display {
    font-size: 3.5rem;
    font-weight: 700;
    text-align: center;
    color: var(--black);
}
.separator {
    margin: 0;
    padding: 120px 16px;
    text-align: center;
    font-size: 3.5rem;
    font-weight: 700;
    color: var(--black);
}

.section-title {
    font-size: 2.188rem;
    font-weight: 600;
    color: var(--black)
}

.subtitle {
    font-size: 1.5rem;
    font-weight: 600;
    color: var(--body);
}
.section-padding {
    padding-top: 24px;
    padding-bottom: 24px;
}


// BUTTONS
.button-sm {
    &.MuiButton-root {
        height: 36px;
        border-radius: 18px !important;
        .MuiButton-label {
            font-family: 'Montserrat', sans-serif;
            font-size: 0.875rem;
            font-weight: 600;
            text-transform: none;
        }
    }
}

.button-md {
    &.MuiButton-root {
        height: 48px;
        border-radius: 24px !important;
        .MuiButton-label {
            font-family: 'Montserrat', sans-serif;
            font-size: 1rem;
            font-weight: 600;
            text-transform: none;
        }
    }
}

p{
    line-height: 22px;
    color: var(--body);
}

ul {
    padding-left: 24px;
    li {
        font-size: 1rem;
        line-height: 1.8rem;
        color: var(--body);
        list-style-type: disc;
    }
}

.linkedin {
    display: inline-block;
    margin-right: 24px;
    width: 36px;
    height: 36px;
    background: url('../assets/images/icon-linkedin.svg') no-repeat center top / 36px;
    &:hover {
        background-position: center bottom;
    }
}

.behance {
    display: inline-block;
    margin-right: 24px;
    width: 36px;
    height: 36px;
    background: url('../assets/images/icon-behance.svg') no-repeat center top / 36px;
    &:hover {
        background-position: center bottom;
    }
}

.mail {
    display: inline-block;
    width: 36px;
    height: 36px;
    background: url('../assets/images/icon-mail.svg') no-repeat center top / 36px;
    &:hover {
        background-position: center bottom;
    }
}

:root {
    --primary: #4C51DB;
    --primaryDark: #33379A;
    --secondary: #FFD142;
    --secondaryDark: #9F7D10;
    --body: #444444;
    --blackest: #f6f6f6;
    --black: #222222;
    --white: #ffffff;
}


@media (prefers-color-scheme: dark) {
    body {
        background-color: #222222;
    }

    :root {
        --body: #ffffff;
        --black: #ffffff;
        --blackest: #181818;
        --white: #222222;
    }
}