.image {
    display: block;
    width: 100%;
    margin: 40px auto;
}

.h1 {
    font-size: 2rem;
    font-weight: 700;
    text-align: center;
    color: var(--black);
    margin: 0 0 8px
}
.h2 {
    font-size: 1rem;
    font-weight: 400;
    text-align: center;
    color: var(--body);
    margin: 0 0 36px;
}
.paragraph {
    margin-bottom: 56px;
    text-align: center;
}

.resume {
    display:block;
    padding: 12px 16px;
    border-radius: 8px;
    text-decoration: none;
    background-color: #4C51DB;
    text-align: center;
    font-size: 600;
    color: white;
    margin: 0 0 40px;
    transition: all ease 200ms;
    &:hover{
        background-color: #33379A;
    }
}